import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import BarChart from "./charts/barChart";
import HorizontalBar from "./charts/horizontalBar";
import LineChart from "./charts/lineChart";
import { useEffect, useState } from "react";
import PieChart from "./charts/pieChart";
import ScatterChart from "./charts/scatterChart";

export const useStyles = makeStyles()((theme) => ({
  filterLable: {
    fontWeight: "bold",
    margin: "auto 14px auto 0px",
  },

  filterBtnSelect: {
    textTransform: "none",
    margin: "6px 6px 6px 0px",
    borderRadius: "5px",
    padding: "2px 16px",
    fontSize: "11px",
    fontWeight: 600,
    color: "white",
    "&:hover": {
      color: "white",
    },
  },

  filterBtnSelect2: {
    textTransform: "none",
    margin: "6px 6px 6px 0px",
    borderRadius: "5px",
    padding: "2px 16px",
    fontSize: "11px",
    fontWeight: 600,
    backgroundColor: theme.palette.primary.contrastText,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: "white",
    },
  },

  filterBtn: {
    textTransform: "none",
    margin: "6px 6px 6px 0px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "2px 16px",
    fontSize: "11px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  chartBox: {
    border: "1px solid #ddd",
  },
}));

function ChartSection(props: any): JSX.Element {
  const { classes } = useStyles();
  const [chartType, setChartType] = useState("");
  const url = window.location.pathname;

  useEffect(() => {
    let capitalize = props.content.chart_data.title
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    props.content.chart_data.title = capitalize;
    const chartTitle = capitalize;

    setChartType(
      url.includes("labour_risks") ||
        props.content.chart_data.regions[0].data[0].attribute === "Yes" ||
        props.content.chart_data.regions[0].data[0].attribute === "No" ||
        props.content.chart_data.regions[0].data[0].measure?.Yes !== undefined ||
        props.content.chart_data.regions[0].data[0].measure?.No !== undefined
        ? "pie"
        : props.heading.includes("Detailed")
        ? "scatter"
        : chartTitle.includes("Number Of Farmers In") || chartTitle.includes("All Countries") || chartTitle.includes("Under Cultivation")
        ? "line"
        : "bar"
    );
  }, []);

  return (
    <Grid xs={12}>
      {chartType === "horizontalBar" ? (
        <HorizontalBar content={props.content} page={props.page} />
      ) : chartType === "bar" ? (
        <BarChart content={props.content} page={props.page} />
      ) : chartType === "line" ? (
        <LineChart content={props.content} page={props.page} />
      ) : chartType === "pie" ? (
        <PieChart content={props.content} page={props.page} title={props.content.chart_data.title} />
      ) : chartType === "scatter" ? (
        <ScatterChart content={props.content} />
      ) : null}

      {chartType !== "pie" && (
        <Grid className={classes.chartBox} style={{ padding: "14px" }}>
          <Typography variant="body2">{props.content.chart_data.description}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default ChartSection;
