import { Button, Grid, Typography, useTheme } from "@mui/material";
import { useStyles } from "../../ChartSection";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { malawiColors, mozColors, zimColors } from "../../../theme/theme";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

function ChartFilters(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();

  const graphColors = [theme.palette.secondary.light, theme.palette.secondary.dark];
  const countryComp = [zimColors.first, malawiColors.first, mozColors.first];

  console.log("filters");
  console.log(props);

  let graphTitle = props.content.chart_data.title
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  // Function to update filters
  const handleFilterChange = (reg: string, comp: string) => {
    props.filters.region = reg;
    props.filters.component = comp;

    props.filterData({ filters: { region: reg, component: comp }, data: props.data });
  };

  return (
    <Grid style={{ margin: "14px 0px 32px 0px" }}>
      <Grid container>
        <Typography variant="body2" className={classes.filterLable}>
          {props.data.length !== 1 && "Select a Region:"}
        </Typography>

        {props.data.length > 0 &&
          props.data.map((reg: any, index: number) => (
            <Button
              className={props.filters.region === reg.region || props.page === "Country Comparison" ? classes.filterBtnSelect : classes.filterBtn}
              onClick={() => handleFilterChange(reg.region, props.filters.component)}
              style={{
                backgroundColor:
                  props.page === "Country Comparison"
                    ? countryComp[index % countryComp.length]
                    : props.filters.region === reg.region
                    ? graphColors[index % graphColors.length]
                    : "",
              }}
            >
              {reg.region}
            </Button>
          ))}
      </Grid>

      {props.data[0]?.data?.[0]?.filter !== undefined ? (
        <Grid container style={{ marginTop: "16px" }}>
          <Typography variant="body2" className={classes.filterLable}>
            {props.data.length !== 1 && (graphTitle.includes("Impact Score") ? "Select an indicator:" : "Select a core component:")}
          </Typography>

          {props.data.length > 0 &&
            props.data.map(
              (reg: any) =>
                reg.region === props.filters.region &&
                reg.data.map((filtr: any) => (
                  <Button
                    className={props.filters.component === filtr.filter ? classes.filterBtnSelect2 : classes.filterBtn}
                    onClick={() => handleFilterChange(props.filters.region, filtr.filter)}
                  >
                    {filtr.filter}
                  </Button>
                ))
            )}
        </Grid>
      ) : graphTitle.includes("Per Food Group") || graphTitle.includes("Protein, Carbs, Fats") ? (
        <Grid container style={{ marginTop: "16px" }}>
          <Typography variant="body2" className={classes.filterLable}></Typography>

          {props.data.length > 0 &&
            props.data.map((reg: any) =>
              reg.data.map((data: any) => (
                <Button
                  className={props.filters.component === data.attribute ? classes.filterBtnSelect2 : classes.filterBtn}
                  onClick={() => handleFilterChange(props.filters.region, data.attribute)}
                >
                  {data.attribute}
                </Button>
              ))
            )}
        </Grid>
      ) : null}
    </Grid>
  );
}

export default ChartFilters;
