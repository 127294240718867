import { Grid, Typography, useTheme } from "@mui/material";
import { useStyles } from "../ChartSection";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData } from "chart.js";
import ChartFilters from "./components/filter";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();
  const url = window.location.pathname;

  const [filters, setFilters] = useState({
    region: "",
    component: "",
  });

  const [newData, setNewData] = useState();

  const transformData = (input: any) => {
    let newData: any[] = [];

    input.map((region: any) => {
      props.content.chart_data.regions[0].data[0].measure.Partially !== undefined
        ? newData.push({
            data: [
              { attribute: "Yes", measure: region.measure.Yes },
              { attribute: "Partially", measure: region.measure.Partially },
              { attribute: "No", measure: region.measure.No },
            ],
            region: region.attribute,
          })
        : newData.push({
            data: [
              { attribute: "Yes", measure: region.measure.Yes },
              { attribute: "No", measure: region.measure.No },
            ],
            region: region.attribute,
          });
    });

    return newData;
  };

  useEffect(() => {
    let correctFormat =
      props.content.chart_data.regions[0].data[0].measure.Yes !== undefined
        ? transformData(props.content.chart_data.regions[0].data)
        : props.content.chart_data.regions;

    setNewData(correctFormat);

    filters.region = correctFormat[0]?.region;
    setFilters({ ...filters });
    filterData({ filters, data: correctFormat });
  }, []);

  const chartData: ChartData<"pie"> = {
    labels: props.content.chart_data.regions[0].data.map((f: any) => f.attribute),
    datasets: [
      {
        label: "",
        data: props.content.chart_data.regions[0].data.map((d: any) => d.measure),
        backgroundColor: [theme.palette.secondary.dark, theme.palette.secondary.light, theme.palette.primary.contrastText],
        borderWidth: 1,
      },
    ],
  };

  const [graphData, setGraphData] = useState<ChartData<"pie">>(chartData);

  const chartOptions: ChartOptions<"pie"> = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "center",
        align: "center",
        formatter: (value: any, context: any) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${value}% \n ${label}`;
        },
        color: "#fff",
        textAlign: "center",
      },
    },
  };

  const filterData = (propFilter: any) => {
    const matchingRegions = propFilter.data.filter((reg: any) => reg.region === propFilter.filters.region);

    matchingRegions.map((reg: any) => {
      let data = {
        labels: reg.data.map((f: any) => f.attribute),
        datasets: [
          {
            label: "",
            data: reg.data.map((d: any) => d.measure),
            backgroundColor: [theme.palette.secondary.dark, theme.palette.secondary.light, theme.palette.primary.contrastText],
            borderWidth: 1,
          },
        ],
      };

      setGraphData(data);
    });
  };

  return (
    <Grid container className={!url.includes("labour_risks") ? classes.chartBox : ""} style={{ padding: "14px" }}>
      {!url.includes("labour_risks") && newData !== undefined && (
        <ChartFilters content={props.content} data={newData} filterData={(e: any) => filterData(e)} filters={filters} page={props.page} />
      )}
      <Grid container justifyContent="center" alignItems="center">
        <Grid item style={{ width: "fit-content" }}>
          {url.includes("labour_risks") && (
            <Typography variant="body2" style={{ textAlign: "center", marginBottom: "24px", fontWeight: "bold" }}>
              {props.title}
            </Typography>
          )}

          <Pie data={graphData} options={chartOptions} style={{ width: "350px", marginBottom: "10px" }} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PieChart;
