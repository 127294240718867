import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  imgStyle: { height: "100%", width: "100%", objectFit: "cover" },
}));

function ImageSection(props: any): JSX.Element {
  const { classes } = useStyles();

  return (
    <Grid container style={{ height: "430px" }}>
      {props.content.images.length === 4 ? (
        <>
          <Grid item xs={4} style={{ height: "100%" }}>
            <img src={"/" + props.content.images[2]} className={classes.imgStyle} />
          </Grid>
          <Grid item xs={8} container direction="column" style={{ height: "100%" }}>
            <Grid item style={{ height: "50%", padding: "0px 0px 0px 28px" }}>
              <img src={"/" + props.content.images[1]} className={classes.imgStyle} />
            </Grid>
            <Grid item container style={{ height: "50%" }}>
              <Grid item xs={6} style={{ height: "100%", padding: "28px 0px 0px 28px", transform: "rotate(1deg)" }}>
                <img src={"/" + props.content.images[3]} className={classes.imgStyle} />
              </Grid>
              <Grid item xs={6} style={{ height: "100%", padding: "28px 0px 0px 28px", transform: "rotate(-1deg)" }}>
                <img src={"/" + props.content.images[0]} className={classes.imgStyle} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={"/" + props.content.images[0]} className={classes.imgStyle} style={{ objectFit: "contain", height: "100%" }} />
        </Grid>
      )}
    </Grid>
  );
}

export default ImageSection;
