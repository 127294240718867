import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

interface ThemeContextType {
  mode: string;
  handleChangeTheme: (newMode: string) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<string>("dark");

  useEffect(() => {
    // Update theme when themeMode changes
    const savedTheme = localStorage.getItem("appTheme");
    if (savedTheme) {
      setMode(savedTheme);
    }
  }, []);

  const handleChangeTheme = (newMode: string) => {
    setMode(newMode);
    localStorage.setItem("appTheme", newMode);
  };

  const value = useMemo(() => ({ mode, handleChangeTheme }), [mode]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useAppTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useAppTheme must be used within a ThemeProvider");
  }
  return context;
};
