import Amplify from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import config from "./Configs/AwsExports";
import Routes from "./Routing/Routes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./fonts/Nandos-Regular.ttf";

import React from "react";
import { getDesignTokens } from "./theme/theme";
import { useAppTheme } from "./Components/context/ThemeContext";

Amplify.configure(config);

const App = () => {
  const { mode } = useAppTheme();
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode) as any), [mode]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
