import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { Grid, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import PageSection from "../Components/PageSection";

function CountryComparison(): JSX.Element {
  const [pageContent, setPageContent] = useState({ section: [], year: "" });
  const { country, crop, year } = useParams();
  const [noData, setNoData] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    // Get data on page load
    retrievePageContent();
  }, [country, crop, year]);

  const retrievePageContent = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await API.get("aica", "/page", {
        response: true,
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        queryStringParameters: {
          page_id: `Country-Comparison_${year}_${crop}`,
          inc_chart_data: "yes",
        },
      });

      if (response.data?.Message) {
        console.log(response.data.Message);
        setNoData(true);
      } else {
        setNoData(false);
        setPageContent(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid xs={12}>
      <Grid className="backdropTitle" style={{ backgroundColor: theme.palette.secondary.contrastText }}>
        <Typography variant="h1">Country Comparison</Typography>
      </Grid>

      <Grid className="innerPageLayout">
        {noData ? (
          <Typography variant="subtitle1">No Data To Display</Typography>
        ) : (
          <>
            {/* @ts-ignore */}
            {pageContent ? (
              pageContent.section.map((section) => {
                return <PageSection section={section} year={pageContent.year} page={"Country Comparison"} />;
              })
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default CountryComparison;
