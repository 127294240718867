import { Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  rightColText: {
    fontSize: "28px",
    fontWeight: "bold",
  },

  simpleTable:{
    // padding of the inner cells
    padding: "20px 16px",
    //background colour: light grey
    backgroundColor: "#E0E0E0",
    border: "10px solid #E0E0E0",
  },

  rightColTextSmall: {
    fontSize: "14px",
  },

  tag: {
    backgroundColor: theme.palette.primary.light,
    padding: "2px 4px",
    transform: "rotate(-2deg)",
    height: "fit-content",
    margin: "auto 0px auto auto",
  },
}));

function TableSection(section_data: any): JSX.Element {
  const { classes } = useStyles();

  return (
    <>
      <Table className={section_data.tableType === "simple" ? classes.simpleTable : ""}>
        <TableBody>
          {section_data.content.map((row: any) => (
            <TableRow key={row.name}>
              <TableCell style={{ padding: "7px 0px", width: "40%" }}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {row.attribute}
                </Typography>
              </TableCell>

              {section_data.content.every((row: any) => typeof row.measure === "object") ? (
                row.measure.map((col: any) => (
                  <TableCell align="right" style={{ padding: "7px 0px" }}>
                    <Typography 
                      variant={section_data.tableType === "simple" ? "body2" : "body1"}  
                      className={section_data.tableType === "simple" ? classes.rightColTextSmall : classes.rightColText} 
                      style={{ padding: "7px 0px" }}
                    >
                      {col}
                    </Typography>
                  </TableCell>
                ))
              ) : (
                <TableCell align="right" style={{ padding: "7px 0px" }}>
                  {typeof row.measure === "object" ? (
                    <Grid container style={{ width: "fit-content", marginLeft: "auto" }}>
                      <Typography variant="body1" className={classes.rightColText} style={{ marginRight: "16px" }}>
                        {row.measure[0]}
                      </Typography>
                      <Typography variant="body2" className={classes.tag}>
                        {row.measure[1]}
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography variant={section_data.tableType === "simple" ? "body2" : "body1"} className={section_data.tableType === "simple" ? classes.rightColTextSmall : classes.rightColText}>
                      {row.measure}
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default TableSection;
