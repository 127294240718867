import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { scrollToTop } from "../Components/PageNavigation";
import PageSection from "../Components/PageSection";
import useStyles from "../Components/stylesNav";

function Introduction(): JSX.Element {
  const [pageContent, setPageContent] = useState({ background: { section: [] }, methodology: { section: [] } });
  const [subPage, setSubPage] = useState("background");
  const { classes } = useStyles();
  const { country, crop, year } = useParams();
  const [noData, setNoData] = useState<boolean>(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Get data on page load
    retrievePageContent();
  }, [country, crop, year]);

  const retrievePageContent = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const responseBackground = await API.get("aica", "/page", {
        response: true,
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        queryStringParameters: {
          page_id: `Introduction_Background_${year}_${crop}`,
          inc_chart_data: "yes",
        },
      });

      const responseMethodology = await API.get("aica", "/page", {
        response: true,
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        queryStringParameters: {
          page_id: `Introduction_Methodology_${year}_${crop}`,
          inc_chart_data: "yes",
        },
      });

      let tempVar = { background: { section: [] }, methodology: { section: [] } };

      if (responseBackground.data?.Message) {
        console.log("No Data To Display");
      } else {
        tempVar.background = responseBackground.data;
      }

      if (responseMethodology.data?.Message) {
        console.log("No Data To Display");
      } else {
        tempVar.methodology = responseMethodology.data;
      }

      setPageContent(tempVar);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid xs={12}>
      <Grid item style={{ padding: mobile ? "145px 21px 0px 21px" : "0px" }}>
        <Grid
          className="backdropTitle"
          style={{
            backgroundColor: theme.palette.secondary.contrastText,
          }}
        >
          <Typography variant="h1">Introduction</Typography>
        </Grid>

        <Grid container>
          <Typography variant="h6" onClick={() => setSubPage("background")} className={subPage === "background" ? classes.menuItemRed : ""}>
            Background
          </Typography>

          <Typography
            variant="h6"
            onClick={() => setSubPage("methodology")}
            className={subPage === "methodology" ? `${classes.menuItem} ${classes.tiltRight}` : classes.tiltRight}
          >
            Methodology
          </Typography>
        </Grid>
      </Grid>

      <Grid className="innerPageLayout">
        {subPage === "background" ? (
          <Grid item>
            <>
              {noData ? (
                <Typography variant="subtitle1">No Data To Display</Typography>
              ) : (
                <>
                  {/* @ts-ignore */}
                  {pageContent ? (
                    /* @ts-ignore */
                    pageContent.background.section.map((section) => {
                      /* @ts-ignore */
                      return <PageSection section={section} year={pageContent.year} />;
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          </Grid>
        ) : (
          <Grid item>
            <>
              {noData ? (
                <Typography variant="subtitle1">No Data To Display</Typography>
              ) : (
                <>
                  {/* @ts-ignore */}
                  {pageContent ? (
                    /* @ts-ignore */
                    pageContent.methodology.section.map((section) => {
                      /* @ts-ignore */
                      return <PageSection section={section} year={pageContent.year} />;
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          </Grid>
        )}

        <Grid className={classes.whiteCircle} onClick={scrollToTop}>
          <img className={classes.topIcon} src="/back-to-top.svg" alt="top" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Introduction;
