import { Grid, useTheme } from "@mui/material";
import { useStyles } from "../ChartSection";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(BarElement, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, ChartDataLabels);

function LineChart(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();
  const [graphData, setGraphData] = useState({
    labels: [] as string[],
    datasets: [] as any[],
  });

  const [filters, setFilters] = useState({
    region: "",
    component: "",
  });

  useEffect(() => {
    filters.region = props.content.chart_data.regions?.[0].region;

    filterData(filters);
  }, []);

  type LineOptions = ChartOptions<"line">;

  const initialOptions: LineOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "top",
        formatter: (value) => value,
      },
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "",
        },
        beginAtZero: true,
      },
    },
  };

  const [lineOptions, setLineOptions] = useState<LineOptions>(initialOptions);

  const filterData = (propFilter: any) => {
    const matchingRegions = props.content.chart_data?.regions.filter((reg: any) => reg.region === propFilter.region);

    matchingRegions.map((reg: any) => {
      setLineOptions((prevOptions) => ({
        ...prevOptions,
        scales: {
          ...prevOptions.scales,
          y: {
            ...prevOptions.scales?.y,
            title: {
              ...prevOptions.scales?.y?.title,
              text: props.content.chart_data["y-label"],
            },
          },
        },
      }));

      let data = {
        labels: reg.data.map((f: any) => f.attribute),
        datasets: [
          {
            label: "",
            data: reg.data.map((d: any) => d.measure),
            borderColor: props.content.heading.includes("cultivation ") ? theme.palette.secondary.dark : theme.palette.success.light,
            borderWidth: 2,
            tension: 0.4,
          },
        ],
      };

      setGraphData(data);
    });
  };

  return (
    <Grid item className={classes.chartBox} style={{ padding: "14px" }}>
      <Line data={graphData} options={lineOptions} />
    </Grid>
  );
}

export default LineChart;
