import { Typography, Grid } from "@mui/material";
import TextSection from "./TextSection";
import TableSection from "./TableSection";
import ChartSection from "./ChartSection";
import { makeStyles } from "tss-react/mui";
import ImageSection from "./ImageSection";
import ImageTableSection from "./ImageTableSection";

export const useStyles = makeStyles()((theme) => ({
  subHeading1: {
    fontSize: "28px",
    color: theme.palette.secondary.main,
    marginBottom: "21px",
    // make uppercase
    textTransform: "uppercase",
    // underline text
    textDecoration: "underline",
  },
  subHeading2: {
    fontSize: "21px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    lineHeight: 1.2,
    marginBottom: "10px",
  },
  chartBox: {
    border: "1px solid #ddd",
    padding: "14px",
  },
}));

function PageSection(props: any): JSX.Element {
  const { classes } = useStyles();
  const url = window.location.pathname;

  let heading = props.section?.heading.includes("Success") ? "Success, Challenges and Farmer Feedback" : props.section?.heading;

  const renderSectionByType: any = (section_data: any) => {
    let heading = section_data.heading
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    switch (section_data?.type) {
      case "text":
        return (
          <Grid className="sub-section">
            {section_data.text && <TextSection content={section_data} />}
            {section_data.subsection?.map((subsection: any, index: number) => (
              <Grid key={index}>
                {subsection.type === "text" && (
                  <>
                    <hr />
                    <Typography className={classes.subHeading1}>{subsection.heading}</Typography>
                  </>
                )}

                {renderSectionByType(subsection)}
              </Grid>
            ))}
          </Grid>
        );
      case "table":
        return (
          <Grid className="sub-section">
            {section_data.table && <TableSection content={section_data.table} tableType={section_data.table_type} />}
            {section_data.subsection?.map((subsection: any, index: number) => (
              <Grid key={index}>{renderSectionByType(subsection)}</Grid>
            ))}
          </Grid>
        );
      case "chart":
        return (
          <Grid className={typeof section_data.chart_data === "string" ? "hide_secion" : "sub-section"} xs={12} style={{ margin: "auto" }}>
            {typeof section_data.chart_data === "string" ? (
              <>
                {/* <hr />
                <Typography className={classes.subHeading1}>{heading}</Typography>
                {section_data.text ? <TextSection content={section_data} /> : <Typography variant="body2">{section_data.chart_data}</Typography>} */}
              </>
            ) : url.includes("labour_risks") ? (
              <ChartSection content={section_data} year={props.year} page={props.page} />
            ) : (
              <>
                <Grid className={classes.chartBox}>
                  <Typography className={classes.subHeading2}>{heading}</Typography>
                  <Typography variant="subtitle2">{props.year}</Typography>
                </Grid>
                <ChartSection content={section_data} year={props.year} page={props.page} heading={heading} />
              </>
            )}

            {section_data.subsection?.map((subsection: any, index: number) => (
              <Grid key={index}>{renderSectionByType(subsection)}</Grid>
            ))}
          </Grid>
        );
        case "image":
          return (
            <Grid className="sub-section">
              <ImageSection content={section_data} />
            </Grid>
          );
          case "image-table":
            return (
              <Grid className="sub-section">
                <ImageTableSection content={section_data} />
              </Grid>
            );
      default:
        return <Typography variant="body2">No Data</Typography>;
    }
  };

  return (
    <Grid
      className={heading.includes("Over Time") && props.section.subsection?.[0]?.chart_data === "No Data Found" ? "hide_secion" : "section"}
      id={heading.replace(/[\s,]+/g, "")}
    >
      {props.section.type !== "chart" &&
        heading &&
        !(heading.includes("Over Time") && props.section.subsection?.[0]?.chart_data === "No Data Found") && (
          <Typography variant="h5">{heading}</Typography>
        )}

      {renderSectionByType(props.section)}

      {heading === "Overview" && props.allSections}
    </Grid>
  );
}

export default PageSection;
