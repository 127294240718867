import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageNavigation from "../Components/PageNavigation";
import { API, Auth } from "aws-amplify";
import { Grid, Typography } from "@mui/material";
import PageSection from "../Components/PageSection";
import PageInnerNav from "../Components/PageInnerNav";

function Background(): JSX.Element {
  const { country, crop, year } = useParams();
  const [pageContent, setPageContent] = useState({ section: [], year: "" });
  const [allSections, setAllSections] = useState<string[]>([]);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    // Get data on page load
    retrievePageContent();
  }, [country, crop, year]);

  const retrievePageContent = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await API.get("aica", "/page", {
        response: true,
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        queryStringParameters: {
          page_id: `${country}_background_${year}_${crop}`,
          inc_chart_data: "yes",
        },
      });

      if (response.data?.Message) {
        console.log(response.data.Message);
        setNoData(true);
      } else {
        setNoData(false);
        let tempArray: string[] = [];

        response.data.section.forEach((section: any) =>
          section.heading !== "Overview"
            ? section.heading.includes("Success")
              ? tempArray.push("Success, Challenges and Farmer Feedback")
              : tempArray.push(section.heading)
            : null
        );

        setAllSections(tempArray);

        setPageContent(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid xs={12}>
      <PageNavigation />

      <Grid className="innerPageLayout">
        {noData ? (
          <Typography variant="subtitle1">No Data To Display</Typography>
        ) : (
          <>
            {/* @ts-ignore */}
            {pageContent ? (
              pageContent.section.map((section) => {
                return <PageSection section={section} allSections={<PageInnerNav content={allSections} />} year={pageContent.year} />;
              })
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Background;
