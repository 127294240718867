import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Pages/Login";
import PrivateRoutes from "../Routing/PrivateRoutes";
import Introduction from "../Pages/Introduction";
import Background from "../Pages/Background";
import Farmers from "../Pages/Farmers";
import Production from "../Pages/Production";
import Impact from "../Pages/Impact";
import LabourRisks from "../Pages/LabourRisks";
import CountryComparison from "../Pages/CountryComparison";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/introduction/:crop/:year" element={<Introduction />} />
          <Route path="/background/:country/:crop/:year" element={<Background />} />
          <Route path="/farmers/:country/:crop/:year" element={<Farmers />} />
          <Route path="/production/:country/:crop/:year" element={<Production />} />
          <Route path="/labour_risks/:country/:crop/:year" element={<LabourRisks />} />
          <Route path="/impact/:country/:crop/:year" element={<Impact />} />
          <Route path="/country_comparison/:crop/:year" element={<CountryComparison />} />
        </Route>
        <Route path="*" element={<Navigate to="/introduction/ABE/2022-2023" replace />} />
      </Routes>
    </>
  );
};

export default Routing;
