import React from 'react';
import { Typography } from "@mui/material";

const TextSection = (props: any): JSX.Element => {
  const text = props.content.text.includes("\n\n") ? props.content.text.split("\n\n") : [props.content.text];
  
  const createMarkup = (htmlContent: string) => {
    return { __html: htmlContent };
  };

  const renderLine = (line: string, index: number) => {
    const isBold =
      (line.length < 71 && line.substring(0, 1) !== "(" && !line.includes("USD") && !line.includes("Yields into")) ||
      line.includes("To ensure the data is comparable");

    const bullet =
      line === "ABE weight into kilograms of fresh chilli, as it is sold fresh and dry;" ||
      line === "Currencies into USD;" ||
      line === "Yields into .25ha plot sizes; and" ||
      line ===
        "Net income to farmers, by subtracting farming inputs (seedlings, fertiliser and pesticides), paid labour, tools, transportation and other expenses from the farmers' gross earnings.";

    return (
      <Typography
        key={index}
        variant="body2"
        component="div"
        style={{
          fontWeight: isBold ? "bold" : "normal",
          marginBottom: isBold ? "8px" : "0px",
          // if isBold, then underline
          textDecoration: isBold ? "underline" : "none",
          // if isBold, then uppercase
          textTransform: isBold ? "uppercase" : "none",
        }}
      >
        {bullet ? (
          <ul style={{ margin: "4px 0px", paddingLeft: "1rem" }}>
            <li dangerouslySetInnerHTML={createMarkup(line)} />
          </ul>
        ) : (
          <div dangerouslySetInnerHTML={createMarkup(line)} />
        )}
      </Typography>
    );
  };

  return (
    <>
      {text.map((paragraph: string, paragraphIndex: number) => (
        <React.Fragment key={paragraphIndex}>
          {paragraph.split("\n").map((line: string, lineIndex: number) => 
            renderLine(line, lineIndex)
          )}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default TextSection;