import { useEffect, useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { useStyles } from "../ChartSection";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

function HorizontalBar(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();
  const [data, setData] = useState([]);

  const transformData = (input: any) => {
    let newData: any[] = [];

    input.map((region: any) => {
      props.content.chart_data.regions[0].data[0].measure.Partially !== undefined
        ? newData.push({
            data: [
              { attribute: "Yes", measure: region.measure.Yes },
              { attribute: "Partially", measure: region.measure.Partially },
              { attribute: "No", measure: region.measure.No },
            ],
            region: region.attribute,
          })
        : newData.push({
            data: [
              { attribute: "Yes", measure: region.measure.Yes },
              { attribute: "No", measure: region.measure.No },
            ],
            region: region.attribute,
          });
    });

    return newData;
  };

  useEffect(() => {
    let correctFormat =
      props.content.chart_data.regions[0].data[0].measure.Yes !== undefined
        ? transformData(props.content.chart_data.regions[0].data)
        : props.content.chart_data.regions;

    setData(correctFormat);
  }, []);

  const options: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "start",
        align: "start",
        offset: -20,
        padding: {
          left: -30,
          right: 0,
          top: 0,
          bottom: 0,
        },
        font: {
          weight: "bold",
          size: 11,
        },
        formatter: (value) => value + "%",
      },
    },
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
  };

  const horizontalData = (reg: any): ChartData<"bar"> => {
    return {
      labels: reg.data.map((lbl: any) => lbl.attribute),
      datasets: [
        {
          label: "",
          data: reg.data.map((d: any) => d.measure),
          backgroundColor:
            reg.data.length === 3
              ? [theme.palette.secondary.light, theme.palette.secondary.dark, theme.palette.primary.contrastText]
              : [theme.palette.secondary.light, theme.palette.secondary.dark],
          borderColor: [theme.palette.success.light, theme.palette.secondary.main],
        },
      ],
    };
  };

  return (
    <Grid container className={classes.chartBox}>
      {data.slice(0, 4).map((reg: any) => (
        <Grid item xs={12} sm={6} key={reg.region} style={{ padding: "14px" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "8px" }}>
            {reg.region}
          </Typography>
          <Bar data={horizontalData(reg)} options={options} style={{ maxHeight: "130px" }} />
        </Grid>
      ))}
    </Grid>
  );
}

export default HorizontalBar;
