import { useEffect, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import { useStyles } from "../ChartSection";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, PointElement, LinearScale, Title, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import ChartFilters from "./components/filter";

ChartJS.register(PointElement, LinearScale, Title, Tooltip, Legend);

function ScatterChart(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();
  const [filters, setFilters] = useState({
    region: "",
    component: "",
  });
  const [graphData, setGraphData] = useState({
    datasets: [] as any[],
  });

  useEffect(() => {
    let data = props.content.chart_data.regions;

    filters.region = data[0]?.region;

    filterData({ filters, data: data });
  }, []);

  const getColor = (value: number): string => {
    if (value < 500) return "#C1A8A1";
    if (value < 1000) return theme.palette.secondary.main;
    if (value < 1500) return theme.palette.secondary.dark;
    if (value < 2000) return theme.palette.secondary.light;
    if (value < 0) return "#333333";
    return theme.palette.success.light;
  };

  // Try put this into filter component? for bar as well
  const filterData = (propFilter: any) => {
    const matchingRegions = propFilter.data.filter((reg: any) => reg.region === propFilter.filters.region);

    matchingRegions.map((reg: any) => {
      console.log(reg);

      let datapoints: { x: number; y: any }[] = [];

      reg.data.map((datapoint: any) => {
        let xValue;
        switch (datapoint.attribute) {
          case "Less than $0":
            xValue = -1;
            break;
          case "$2000+":
            xValue = 2000;
            break;
          default:
            const range = datapoint.attribute.split("-").map((s: any) => s.replace("$", ""));
            xValue = (parseInt(range[0]) + parseInt(range[1])) / 2;
        }

        // for (let index = 0; index < datapoint.measure; index++) {
        //   datapoints.push({ x: xValue, y: index / datapoint.measure });
        // }
        datapoints.push({ x: xValue, y: datapoint.measure });
      });

      let data = {
        datasets: [
          {
            label: "",
            data: datapoints,
            radius: 5,
            backgroundColor: (context: any) => {
              const value = context.raw.x;
              return getColor(value);
            },
            borderColor: "black",
          },
        ],
      };

      console.log(data);
      setGraphData(data);
    });
  };

  const options: ChartOptions<"scatter"> = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Total: ${context.parsed.y}`;
          },
        },
      },
    },
  };

  return (
    <Grid item className={classes.chartBox} style={{ padding: "14px" }}>
      <ChartFilters content={props.content} data={props.content.chart_data.regions} filterData={(e: any) => filterData(e)} filters={filters} />
      <Scatter data={graphData} options={options} />
    </Grid>
  );
}

export default ScatterChart;
