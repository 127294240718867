import { Navigate } from "react-router-dom";
import withAuthentication from "./WithAuth";
import SiteNavigation from "../Components/SiteNavigation";

//@ts-ignore
const PrivateRoute = ({ children, isValid, ...rest }) => {
  return isValid ? <SiteNavigation /> : <Navigate to="/login" />;
};

export default withAuthentication(PrivateRoute);
