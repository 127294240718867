import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  sideBtnSelected: {
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    padding: "8px 21px",
    width: "fit-content",
    transform: "rotate(-1deg)",
    margin: "0px 0px 14px -1px",
    display: "flex",
    justifyContent: "center",
    transition: "transform 0.15s ease-in-out",

    "&:hover": {
      transform: "rotate(1deg)",
      backgroundColor: theme.palette.secondary.main,
    },
  },

  sideBtn: {
    backgroundColor: theme.palette.primary.light,
    padding: "8px 21px",
    width: "fit-content",
    transform: "rotate(-1deg)",
    margin: "0px 0px 14px -1px",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    background: `linear-gradient(to left, ${theme.palette.primary.light} 50%, ${theme.palette.secondary.main} 50%)`,
    backgroundSize: "200% 100%",
    backgroundPosition: "right",
    transition: "background-position 0.3s ease-in-out",

    "&:hover": {
      transform: "rotate(1deg)",
      color: "white",
      backgroundPosition: "left",
    },
  },

  switchRotate: {
    transform: "rotate(1deg)",
    "&:hover": {
      transform: "rotate(-1deg)",
    },
  },

  select: {
    marginLeft: "10px",
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    padding: "14px 8px",

    "& .MuiOutlinedInput-input": {
      padding: "0px",
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },

  logOut: {
    position: "absolute",
    bottom: -80,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    width: "auto",
    margin: "0 auto",
    padding: "24px",
  },

  // Page Navigation
  menuItemBlack: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  // 1st tab
  menuItemRed: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },

  // 2de tab
  menuItemSec: {
    backgroundColor: theme.palette.secondary.light,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  // 3de tab
  menuItemThird: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },

  // 4th tab
  menuItem: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  tiltRight: {
    transform: "rotate(1deg)",
    "&:hover": {
      transform: "rotate(-1deg)",
    },
  },
  topIcon: {
    width: "56px",
    padding: "4px",
    cursor: "pointer",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(0.9) rotate(-6deg)",
    },
  },
  whiteCircle: {
    position: "fixed",
    backgroundColor: "white",
    bottom: 40,
    right: 40,
    display: "flex",
    boxShadow: "0 15px 15px #0003",
    borderRadius: "100%",
  },
}));
