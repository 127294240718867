import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  imgStyle: { height: "100%", width: "100%", objectFit: "cover" },
  container: { 
    display: 'flex', 
    gap: '16px' 
  },
  iconContainer: { 
    width: '96px', 
    height: '96px', 
    flexShrink: 0 
  },
  title: { 
    margin: '0 0 8px 0', 
    fontSize: '16px', 
    fontWeight: 500 
  },
  description: { 
    margin: 0, 
    fontSize: '14px', 
    fontFamily: 'Roboto, sans-serif',
}
}));

function ImageTableSection(props: any): JSX.Element {
  const { classes } = useStyles();

  return (
    <Grid container spacing={3} >
      {props.content.rows.map((row: any, index: number) => (
        <Grid item xs={4} key={index}>
          <div className={classes.container}>
            <div className={classes.iconContainer}>
              <img src={"/" + row.image} className={classes.imgStyle} alt={row.title} />
            </div>
            <div>
              <h3 className={classes.title}>{row.title}</h3>
              <p className={classes.description}>{row.text}</p>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default ImageTableSection;