import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { Grid, Typography } from "@mui/material";
import PageSection from "../Components/PageSection";
import PageNavigation from "../Components/PageNavigation";
import { useParams } from "react-router-dom";

function LabourRisks(): JSX.Element {
  const { country, crop, year } = useParams();
  const [pageContent, setPageContent] = useState({ section: [], year: "" });
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    // Get data on page load
    retrievePageContent();
  }, [country, crop, year]);

  const retrievePageContent = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await API.get("aica", "/page", {
        response: true,
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        queryStringParameters: {
          page_id: `${country}_labour-risk_${year}_${crop}`,
          inc_chart_data: "yes",
        },
      }).then((response) => {
        let tempVar = { data: { section: [] }, charts: { section: [] } };

        if (response.data?.Message) {
          console.log("No Data To Display");
          setNoData(true);
        } else {
          setNoData(false);
          tempVar.data = response.data;
        }

        if (response.data.section[1].subsection[1]?.chart_data === undefined) {
          (async () => {
            const responseCharts = await API.get("aica", "/chart", {
              response: true,
              headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
              },
              queryStringParameters: {
                page_id: `${country}_labour-risk_${year}_${crop}`,
              },
            });

            if (responseCharts.data?.Message) {
              console.log("No Data To Display");
              setNoData(true);
            } else {
              setNoData(false);
              tempVar.charts = responseCharts.data;
            }

            // Load chart data into data object
            response.data.section.map((subsection: any) => {
              if (subsection.subsection !== undefined) {
                subsection.subsection.map((chartData: any) => {
                  if (typeof chartData.chart === "string") {
                    let chartSubsection = { chart: chartData.chart, type: "chart", chart_data: {}, heading: "" };
                    chartSubsection.chart_data = responseCharts.data[chartData.chart];
                    chartData.subsection = [chartSubsection];
                  } else if (typeof chartData.chart === "object") {
                    let multipleCharts: any[] = [];

                    chartData.chart.map((chart: any) => {
                      multipleCharts.push({ chart: chart.chart, type: "chart", chart_data: responseCharts.data[chart], heading: "" });
                    });
                    chartData.subsection = multipleCharts;
                  }
                });
              }
            });

            setPageContent(response.data);
          })();
        } else {
          setPageContent(response.data);
        }
      });
    } catch (e) {
      console.error(e);
      setNoData(true);
    }
  };

  return (
    <Grid xs={12}>
      <PageNavigation />

      <Grid className="innerPageLayout">
        {noData ? (
          <Typography variant="subtitle1">No Data To Display</Typography>
        ) : (
          <>
            {/* @ts-ignore */}
            {pageContent ? (
              pageContent.section.map((section) => {
                return <PageSection section={section} year={pageContent.year} />;
              })
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default LabourRisks;
