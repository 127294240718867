import { createTheme } from "@mui/material/styles";

const nandosColors = {
  black: "#000000",
  darkGrey: "#CCCBCC",
  darkerGrey: "#7F7F7F",
  lightGrey: "#2d29291a",
  red: "#D2182F",
  green: "#287A35",
  lightGreen: "#9AC746",
  yellow: "#ffad43",
  orange: "#FF7B00",

  teal: "#006666",
  purple: "#5A2781",
};

export const zimColors = {
  first: "#287A35",
  second: "#CC9900",
};

export const malawiColors = {
  first: "#990033",
  second: "#808000",
};

export const mozColors = {
  first: "#104862",
  second: "#C04F15",
};

export const getDesignTokens = (mode: string) => {
  const baseTheme = createTheme();

  return {
    palette: {
      primary: {
        main: nandosColors.black,
        light: nandosColors.lightGrey,
        dark: nandosColors.darkGrey,
        contrastText: nandosColors.darkerGrey,
      },

      // Theme
      secondary: {
        main: nandosColors.red,
        light: mode.includes("zim") ? zimColors.first : mode.includes("mal") ? malawiColors.first : mode.includes("moz") && mozColors.first,
        dark: mode.includes("zim") ? zimColors.second : mode.includes("mal") ? malawiColors.second : mode.includes("moz") && mozColors.second,
        contrastText: mode.includes("Cayenne") ? nandosColors.teal : mode.includes("Paprika") ? nandosColors.purple : nandosColors.black,
      },

      success: {
        main: nandosColors.green,
        light: nandosColors.lightGreen,
      },

      error: { main: nandosColors.red },
    },

    typography: {
      fontFamily: "Nandos hand alt, sans-serif",

      // Nando's Page Titles
      h1: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "63px",
        fontWeight: 500,
        color: "white",
        width: "fit-content",
        transform: "rotate(2deg)",
        paddingRight: "8px",
        [baseTheme.breakpoints.down("md")]: {
          fontSize: "38px",
        },
      },

      // Nando's Sub Page Titles
      h2: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "32px",
        fontWeight: 500,
        color: "white",
        backgroundColor: mode.includes("Cayenne") ? nandosColors.teal : mode.includes("Paprika") ? nandosColors.purple : nandosColors.black,
        width: "fit-content",
        transform: "rotate(-1deg)",
        padding: "0px 8px 0px 0px ",
      },

      // Year Tag
      h3: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "white",
        fontWeight: 700,
        backgroundColor: nandosColors.black,
        width: "fit-content",
        transform: "rotate(5deg)",
        padding: "10px 18px",
        textAlign: "center",
        height: "fit-content",
        margin: "auto 0px",
      },

      // Nando's small sub page titles - no block & straight
      h4: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "32px",
        fontWeight: 500,
        color: nandosColors.black,
        width: "fit-content",
        marginBottom: "24px",
      },

      // Nando's small sub page titles - overview
      h5: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "32px",
        fontWeight: 500,
        color: "white",
        backgroundColor: mode.includes("Cayenne") ? nandosColors.teal : mode.includes("Paprika") ? nandosColors.purple : nandosColors.black,
        width: "fit-content",
        transform: "rotate(-2deg)",
        padding: "4px 12px",
        margin: "24px 0px",
        boxShadow: "0 14px 14px -5px #00000029",
        position: "sticky",
        top: "30px",
        zIndex: "1",
        [baseTheme.breakpoints.down("md")]: {
          fontSize: "28px",
          top: "145px",
        },
      },

      // Nando's small page titles
      h6: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "16px",
        fontWeight: 500,
        width: "fit-content",
        transform: "rotate(-1deg)",
        padding: "10px 21px",
        transition: "transform 0.15s ease",
        margin: "0px 14px 14px 0px",
        backgroundColor: nandosColors.lightGrey,
        color: nandosColors.black,

        "&:hover": {
          transform: "rotate(1deg)",
          cursor: "pointer",
        },
      },

      // Nando's 16
      subtitle1: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "16px",
        fontWeight: 500,
      },

      // Roboto 16
      subtitle2: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
      },

      //  Nando's 14
      body1: {
        fontFamily: "Nandos, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
      },

      // Roboto 14
      body2: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  };
};
