import { useEffect, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import { useStyles } from "../ChartSection";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartFilters from "./components/filter";
import { malawiColors, mozColors, zimColors } from "../../theme/theme";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

function BarChart(props: any): JSX.Element {
  const { classes } = useStyles();
  const theme = useTheme();

  const [graphData, setGraphData] = useState({
    labels: [] as string[],
    datasets: [] as any[],
  });
  const [formatData, setFormatData] = useState([{ data: [] as { filter?: any }[] }]);

  const [filters, setFilters] = useState({
    region: "",
    component: "",
  });

  // Bar colors
  const graphColors = [theme.palette.secondary.light, theme.palette.secondary.dark];
  const countryComp = [zimColors.first, malawiColors.first, mozColors.first];

  let backgroundColors = [graphColors[0], graphColors[1]];

  if (graphData && graphData.datasets && graphData.datasets[0] && graphData.datasets[0].data) {
    backgroundColors = graphData.datasets[0].data.map((_: any, index: number) => (index % 2 === 0 ? graphColors[0] : graphColors[1]));
  }

  // Transform data that has measure as an object into seperate regions
  const transformData = (input: any) => {
    // These data sets get transformed differently as Chikombedzi is a region and not attribute
    if (input[0].data[0].measure?.Chikombedzi !== undefined) {
      let newData = input[0].data[0].measure
        ? Object.keys(input[0].data[0].measure).map((region: any) => ({
            region,
            data: input[0].data.map((reg: any) => ({
              attribute: reg.attribute,
              measure: reg.measure[region],
            })),
          }))
        : [];

      return newData;
    } else {
      const newData = input[0].data.map((reg: any) => ({
        region: reg.attribute,
        data: Object.keys(reg.measure).map((att) => ({
          attribute: att,
          measure: reg.measure[att],
        })),
      }));

      return newData;
    }
  };

  useEffect(() => {
    let correctFormat =
      typeof props.content.chart_data.regions?.[0]?.data?.[0]?.measure === "object" &&
      props.content.chart_data.regions?.[0].data[0].attribute !== "Proteins"
        ? transformData(props.content.chart_data.regions)
        : props.content.chart_data.regions;

    setFormatData(correctFormat);

    // Set second filter
    filters.component =
      props.content.chart_data.regions?.[0].data[0].attribute === "Proteins"
        ? correctFormat[0].data[0]?.attribute
        : correctFormat[0].data[0]?.filter !== undefined
        ? correctFormat[0].data[0]?.filter
        : "";

    filters.region = correctFormat[0]?.region;

    filterData({ filters, data: correctFormat });
  }, []);

  const [chartOptions, setChartOptions] = useState<ChartOptions<"bar">>({
    layout: {
      padding: {
        top: 24,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 10,
        },
        formatter: (value) => (props.content.chart_data["y-label"].includes("Percentage") ? value + "%" : value.toLocaleString()),
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "",
          padding: {
            top: 10,
          },
          color: theme.palette.primary.main,
          font: {
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "",
          padding: {
            bottom: 20,
          },
          color: theme.palette.primary.main,
          font: {
            weight: "bold",
          },
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  });

  const filterData = (propFilter: any) => {
    // Setting X-Axis and Y-Axis Labels
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        y: {
          ...prevOptions.scales?.y,
          title: {
            ...prevOptions.scales?.y?.title,
            text: props.content.chart_data["y-label"],
          },
        },
        x: {
          ...prevOptions.scales?.x,
          title: {
            ...prevOptions.scales?.x?.title,
            text: props.content.chart_data["x-label"],
          },
        },
      },
    }));

    if (props.page === "Country Comparison") {
      const matchingRegions = propFilter.data.map((reg: any) => reg);

      matchingRegions.map((reg: any) => {
        let data = {
          labels: matchingRegions.map((reg: any) => reg.region),
          datasets: [
            {
              label: "",
              data: matchingRegions.map((reg: any) => reg.data.map((d: any) => d.measure)),
              backgroundColor: countryComp,
              borderColor: countryComp,
            },
          ],
        };

        setGraphData(data);
      });
    } else {
      const matchingRegions = propFilter.data.filter((reg: any) => reg.region === propFilter.filters.region);

      let colorIndex = 0;
      propFilter.data.map((reg: any, index: number) => (reg.region === propFilter.filters.region ? (colorIndex = index) : null));

      matchingRegions.map((reg: any) => {
        const fltr = reg.data.find((fltr: any) => fltr.filter === propFilter.filters.component);
        const fltr2 = reg.data.find((fltr: any) => fltr.attribute === propFilter.filters.component);

        let data = {
          labels:
            fltr === undefined
              ? fltr2 !== undefined
                ? ["Good", "Moderate", "Inadequate"]
                : reg.data.map((f: any) => f.attribute)
              : fltr.filters.map((f: any) => f.attribute),
          datasets: [
            {
              label: "",
              data:
                fltr === undefined
                  ? fltr2 !== undefined
                    ? Object.values(fltr2.measure)
                    : reg.data.map((f: any) => f.measure)
                  : fltr.filters.map((d: any) => d.measure),
              backgroundColor: backgroundColors,
              borderColor: backgroundColors,
            },
          ],
        };

        setGraphData(data);
      });
    }
  };

  return (
    <Grid item className={classes.chartBox} style={{ padding: "14px" }}>
      {props.page !== "Country Comparison" && (
        <ChartFilters content={props.content} data={formatData} filterData={(e: any) => filterData(e)} filters={filters} page={props.page} />
      )}
      <Bar data={graphData} options={chartOptions} style={{ maxHeight: "300px" }} />
    </Grid>
  );
}

export default BarChart;
