import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ZW, MW, MZ } from "country-flag-icons/react/3x2";
import useStyles from "./stylesNav";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function PageNavigation(): JSX.Element {
  const { country, crop, year } = useParams();
  const { classes } = useStyles();
  const url = window.location.pathname;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Get data on page load
  }, []);

  return (
    <Grid container style={{ padding: mobile ? "145px 21px 0px 21px" : "0px" }}>
      <Grid
        className="backdropTitle"
        style={{
          backgroundColor: theme.palette.secondary.contrastText,
        }}
      >
        <Typography variant="h1">
          {country === "Zimbabwe" ? (
            <ZW title="Zimbabwe" style={{ transform: "rotate(4deg)", width: "76px", marginRight: "10px" }} />
          ) : country === "Malawi" ? (
            <MW title="Malawi" style={{ transform: "rotate(4deg)", width: "76px", marginRight: "10px" }} />
          ) : (
            <MZ title="Mozambique" style={{ transform: "rotate(4deg)", width: "76px", marginRight: "10px" }} />
          )}
          {country} {crop}
        </Typography>
      </Grid>

      <Grid container>
        <Link to={"/background/" + country + "/" + crop + "/" + year}>
          <Typography variant="h6" className={url.includes("background") ? classes.menuItemRed : ""}>
            Background
          </Typography>
        </Link>

        {!(country == "Malawi" && crop == "Cayenne" && year == "2023-2024") && (
          <Link to={"/farmers/" + country + "/" + crop + "/" + year}>
            <Typography variant="h6" className={url.includes("farmers") ? `${classes.menuItemSec} ${classes.tiltRight}` : classes.tiltRight}>
              Farmers
            </Typography>
          </Link>
        )}

        <Link to={"/production/" + country + "/" + crop + "/" + year}>
          <Typography variant="h6" className={url.includes("production") ? classes.menuItemThird : ""}>
            Production
          </Typography>
        </Link>

        {url.includes("Mozambique") && (
          <Link to={"/labour_risks/" + country + "/" + crop + "/" + year}>
            <Typography variant="h6" className={url.includes("labour_risks") ? classes.menuItemBlack : ""}>
              Labor Risks
            </Typography>
          </Link>
        )}

        <Link to={"/impact/" + country + "/" + crop + "/" + year}>
          <Typography variant="h6" className={url.includes("impact") ? `${classes.menuItem} ${classes.tiltRight}` : classes.tiltRight}>
            {/* if crop is ABE then Impact, otherwise Baseline */}
            {crop === "ABE" ? "Impact" : "Baseline"}
          </Typography>
        </Link>

        {!url.includes("production") && (
          <Typography variant="h3" style={{ margin: mobile ? "14px 0px 14px auto" : "0px 0px 0px auto" }}>
            {year}
          </Typography>
        )}
      </Grid>

      <Grid className={classes.whiteCircle} onClick={scrollToTop}>
        <img className={classes.topIcon} src="/back-to-top.svg" alt="top" />
      </Grid>
    </Grid>
  );
}

export default PageNavigation;
