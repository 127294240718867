import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useStyles from "./stylesNav";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function PageInnerNav(props: any): JSX.Element {
  const { classes } = useStyles();

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <>
      <Grid item style={{ margin: "40px 0px" }}>
        <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "14px" }}>
          Jump to section:
        </Typography>

        <Grid container>
          {props.content.map((heading: string, index: number) => (
            <Link to={`#${heading.replace(/[\s,]+/g, "")}`} key={index}>
              <Typography variant="h6" className={index % 2 === 1 ? classes.tiltRight : ""} style={{ fontSize: "14px", marginBottom: "14px" }}>
                {heading}
              </Typography>
            </Link>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(PageInnerNav);
