import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserPool from "../Configs/UserPool";
import { Grid, TextField, Button, Typography, Box } from "@mui/material";

import { styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  border: 2,
  borderRadius: 3,
  height: 48,
  padding: "0 30px",
  margin: theme.spacing(3, 0, 1),
  backgroundColor: "#333333",
  "&:hover": {
    backgroundColor: "#434343",
    borderColor: "#434343",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const userLogin = () => {
    setLoading(true);
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data: any) => {
        localStorage.setItem("accessToken", data.accessToken.jwtToken);
        setLoading(false);
        navigate("/project");
      },
      onFailure: (error) => {
        console.log("onFailure: ", error);
        localStorage.setItem("accessToken", "none");
        alert("Incorrect username or password.");
        navigate("/");
        return;
      },
    });
  };

  return (
    <Grid xs={12}>
      {loading && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            backgroundColor: "grey",
            height: "100vh",
            zIndex: "9999",
            opacity: "0.4",
            paddingTop: "40vh",
          }}
        >
          <div className="spinner-border float-right" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <Grid container justifyContent="center" alignItems="center" height="100vh" width="100vw">
        <Box border="1px solid black" borderRadius="10px" padding="20px" textAlign="center">
          <img src={process.env.PUBLIC_URL + "/viewer_portal_logo.png"} alt="logo" height="150px" />

          <Typography component="h6" variant="h6">
            <b> Welcome! </b> Sign in to Viewer Portal below
          </Typography>
          <hr />

          <form>
            <>
              <Typography variant="subtitle1"> Enter Email </Typography>

              <StyledTextField
                onChange={(e: any) => setEmail(e.target.value)}
                type="email"
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                InputProps={{}}
              />

              <Typography variant="subtitle1"> Enter Password </Typography>

              <StyledTextField
                onChange={(e: any) => setPassword(e.target.value)}
                type="password"
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                InputProps={{}}
              />

              <StyledButton fullWidth variant="contained" color="primary" onClick={() => userLogin()}>
                Login
              </StyledButton>
            </>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
