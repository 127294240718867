import {
  Box,
  Drawer,
  CssBaseline,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Grid,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { ZW, MW, MZ } from "country-flag-icons/react/3x2";
import { Outlet, Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Auth, API } from "aws-amplify";
import useStyles from "./stylesNav";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useAppTheme } from "./context/ThemeContext";

const drawerWidth = "300px";

export default function SiteNavigation() {
  const { classes } = useStyles();
  const theme = useTheme();
  const { handleChangeTheme } = useAppTheme();

  const { country, crop, year } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const url = window.location.pathname;
  const mobileNav = useMediaQuery(theme.breakpoints.down("md"));

  const [cropSelection, setCropSelection] = useState(crop ? crop : "ABE");
  const [yearSelection, setYearSelection] = useState(year ? year : "2022-2023");
  const [mobileOpen, setMobileOpen] = useState(false);

  const signOut = async () => {
    try {
      setLoading(true);
      await Auth.signOut();
      setLoading(false);
      navigate("/login");
    } catch (error) {
      alert(error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCropChange = (event: SelectChangeEvent) => {
    setCropSelection(event.target.value);
    switch (event.target.value) {
      case "ABE":
        handleChangeTheme(country + event.target.value);
        setYearSelection("2022-2023");
        navigate(`/introduction/${event.target.value}/2022-2023`);
        break;

      case "Cayenne":
        handleChangeTheme(country + event.target.value);
        setYearSelection("2023-2024");
        navigate(`/introduction/${event.target.value}/2023-2024`);
        break;

      case "Paprika":
        handleChangeTheme(country + event.target.value);
        setYearSelection("2023-2024");
        navigate(`/introduction/${event.target.value}/2023-2024`);
        break;

      default:
        handleChangeTheme(country + event.target.value);
        setYearSelection("2021-2022");
        navigate(`/introduction/${event.target.value}/2021-2022`);
        break;
    }
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setYearSelection(event.target.value);
    navigate(`/introduction/${crop}/${event.target.value}`);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            backgroundColor: "grey",
            height: "100vh",
            zIndex: "9999",
            opacity: "0.4",
          }}
        >
          <div className="spinner-border float-right" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}

      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {mobileNav && (
          <AppBar style={{ zIndex: 1300, backgroundColor: "white", boxShadow: "none", paddingRight: "0px" }}>
            <Toolbar>
              <Grid container>
                <img src="/peri-farms-logo.png" style={{ margin: "20px 20px 20px 0px", width: "84px" }} />
                <Typography variant="subtitle2" style={{ margin: "auto auto auto 0px", fontWeight: "700", color: theme.palette.primary.main }}>
                  Impact Dashboard
                </Typography>

                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  style={{ margin: "auto 0px auto auto", width: "auto", height: "50%", padding: "8px" }}
                >
                  <img src="/mobile_nav.svg" style={{ width: "21px" }} />
                </IconButton>
              </Grid>
            </Toolbar>
          </AppBar>
        )}

        <Drawer
          sx={{
            width: mobileNav ? "100%" : drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: mobileNav ? "100%" : drawerWidth,
              boxSizing: "border-box",
              height: mobileNav ? "fit-content" : "100vh",
              overflowY: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              marginTop: mobileNav ? "6rem" : 0,
            },
          }}
          variant={mobileNav ? "temporary" : "permanent"}
          anchor={mobileNav ? "top" : "left"}
          open={mobileNav ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
            hideBackdrop: true,
          }}
          disableScrollLock={true} // Disable scroll lock to prevent padding
        >
          <Grid style={{ width: mobileNav ? "90%" : "76%", margin: "auto", height: "100%" }}>
            <List>
              {!mobileNav && (
                <>
                  {/* Website Logo */}
                  <ListItem disablePadding>
                    <img src="/peri-farms-logo.png" style={{ width: "fit-content", margin: "20px auto 0px" }} />
                  </ListItem>
                  {/* Website Title */}
                  <ListItem style={{ marginBottom: "14px" }}>
                    <Typography variant="subtitle2" style={{ margin: "auto", fontWeight: "700" }}>
                      Impact Dashboard
                    </Typography>
                  </ListItem>
                </>
              )}

              {/* Introduction Navigation Button */}
              <ListItem
                component={Link}
                to={"/introduction/" + cropSelection + "/" + yearSelection}
                disablePadding
                style={{ marginTop: mobileNav ? "24px" : "0px" }}
              >
                <ListItemButton className={url.includes("introduction") ? classes.sideBtnSelected : classes.sideBtn}>
                  <Typography variant="body1">Introduction</Typography>
                </ListItemButton>
              </ListItem>
            </List>

            <Divider sx={{ bgcolor: "black" }} />
            <List>
              {/* Crop Combobox */}
              <ListItem disablePadding style={{ margin: "10px 0px" }}>
                <Typography variant="body1">Crop:</Typography>
                <Select
                  value={cropSelection}
                  onChange={handleCropChange}
                  className={classes.select}
                  displayEmpty
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  <MenuItem value={"ABE"}>ABE</MenuItem>
                  <MenuItem value={"Cayenne"}>Cayenne</MenuItem>
                  <MenuItem value={"Paprika"}>Paprika</MenuItem>
                </Select>
              </ListItem>

              {/* Year Combobox */}
              <ListItem disablePadding>
                <Typography variant="body1">Year:</Typography>
                <Select
                  value={yearSelection}
                  onChange={handleYearChange}
                  className={classes.select}
                  displayEmpty
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  {cropSelection === "ABE" ? <MenuItem value={"2021-2022"}>2021-2022</MenuItem> : <></>}
                  {cropSelection === "ABE" ? <MenuItem value={"2022-2023"}>2022-2023</MenuItem> : <></>}
                  {cropSelection === "Cayenne" || cropSelection === "Paprika" ? <MenuItem value={"2023-2024"}>2023-2024</MenuItem> : <></>}
                </Select>
              </ListItem>
            </List>

            <Typography variant="body1" style={{ margin: "14px auto", textAlign: "center" }}>
              {cropSelection} Producing Countries:
            </Typography>

            <List disablePadding style={{ paddingBottom: mobileNav ? "0px" : "80px" }}>
              {/* Zimbabwe Navigation Button */}
              <ListItem component={Link} to={"/background/Zimbabwe/" + cropSelection + "/" + yearSelection} disablePadding>
                <ListItemButton
                  className={
                    url.includes("Zimbabwe") ? `${classes.sideBtnSelected} + ${classes.switchRotate}` : `${classes.sideBtn} + ${classes.switchRotate}`
                  }
                  onClick={() => handleChangeTheme("zim-" + cropSelection)}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <ZW title="Zimbabwe" className="flag-icon" />
                  </ListItemIcon>
                  <Typography variant="body1">Zimbabwe</Typography>
                </ListItemButton>
              </ListItem>

              {/* Malawi Navigation Button */}
              {cropSelection === "ABE" || cropSelection === "Paprika" ? (
                <ListItem component={Link} to={"/background/Malawi/" + cropSelection + "/" + yearSelection} disablePadding>
                  <ListItemButton
                    className={url.includes("Malawi") ? classes.sideBtnSelected : classes.sideBtn}
                    onClick={() => handleChangeTheme("mal-" + cropSelection)}
                  >
                    <ListItemIcon style={{ minWidth: "40px" }}>
                      <MW title="Malawi" className="flag-icon" />
                    </ListItemIcon>
                    <Typography variant="body1">Malawi</Typography>
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {/* Mozambique Navigation Button */}
              {cropSelection === "ABE" ? (
                <ListItem component={Link} to={"/background/Mozambique/" + cropSelection + "/" + yearSelection} disablePadding>
                  <ListItemButton
                    onClick={() => handleChangeTheme("moz-" + cropSelection)}
                    className={
                      url.includes("Mozambique")
                        ? `${classes.sideBtnSelected} + ${classes.switchRotate}`
                        : `${classes.sideBtn} + ${classes.switchRotate}`
                    }
                  >
                    <ListItemIcon style={{ minWidth: "40px" }}>
                      <MZ title="Mozambique" className="flag-icon" />
                    </ListItemIcon>
                    <Typography variant="body1">Mozambique</Typography>
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {/* Country Comparison Button: only show when crop selection is NOT Cayenne */}
              {cropSelection !== "Cayenne" && (
                <ListItem component={Link} to={"/country_comparison/" + cropSelection + "/" + yearSelection} disablePadding>
                  <ListItemButton
                    className={
                      url.includes("country_comparison")
                        ? `${classes.sideBtnSelected} + ${classes.switchRotate}`
                        : `${classes.sideBtn} + ${classes.switchRotate}`
                    }
                  >
                    <Typography variant="body1">Country Comparison</Typography>
                  </ListItemButton>
                </ListItem>
              )}
            </List>

            <List disablePadding className={classes.logOut}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => signOut()}>
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <LogoutOutlinedIcon />
                  </ListItemIcon>
                  <Typography variant="body1">Logout</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh", bgcolor: "white", padding: mobileNav ? "0px" : "3.5rem 2.5rem 2.5rem 28px" }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
